import {WindowLocation} from '@reach/router'
import {PageRendererProps} from 'gatsby'
import {withTypes} from 'react-final-form'
import {ApolloError} from '@apollo/client'

import {
  useUpdateProfileMutation,
  useProfileQuery,
  StudentPartsFragment,
} from 'src/graphql-generated'
import Layout from 'src/components/Layout'
import ProfileFields from 'src/components/ProfileFields'
import ProfilePreferences from 'src/components/ProfilePreferences'
import AuthRequired from 'src/components/AuthRequired'
import {Header1, Header2} from 'src/components/text'
import {CloseIcon} from 'src/components/icons'
import {Action} from 'src/components/Action'
import Button from 'src/components/Button'
import {goBack} from 'src/lib/util'
import SEO from 'src/components/SEO'
import {Flex, Grid, Box} from 'src/components/Box'
import {ErrorMessage} from 'src/components/form/input'
import {LoadingContentPlaceholder} from 'src/components/Loading'
import {getValidationErrors} from 'src/form/validation'
import {localPaths} from 'src/urls'
import {WindowLocationWithReturnTo} from 'src/types'
import {sendProfileUpdateEvent} from 'src/lib/gsAnalytics'
import {DateFormValue} from 'src/components/form/DateField'
import InterviewCoach from 'src/components/InterviewCoach'

interface FormValues {
  school?: StudentPartsFragment['school']
  graduationYear?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  wantsEmailMessages?: boolean
  birthdate: DateFormValue
}

const {Form} = withTypes<FormValues>()

interface Props {
  location: WindowLocation
}

const Profile = ({location}: Props) => {
  const {loading, data} = useProfileQuery()
  const [mutate] = useUpdateProfileMutation()
  const student = data?.viewer?.student

  if (loading) {
    return <LoadingContentPlaceholder size="large" mt="9" />
  }

  if (!student) {
    return <div>Something went wrong..</div>
  }

  const close = () =>
    goBack(location as WindowLocationWithReturnTo, localPaths.login)

  const onSubmit = async ({
    school,
    graduationYear,
    birthdate,
    ...values
  }: FormValues) => {
    try {
      const attributes = {
        schoolId: school?.id,
        graduationYear: graduationYear ? Number(graduationYear) : undefined,
        birthdate: `${birthdate.year}-${birthdate.month}-${birthdate.day}`,
        ...values,
      }

      await mutate({
        variables: {
          attributes,
        },
      })

      sendProfileUpdateEvent()

      close()
    } catch (e) {
      if (e instanceof ApolloError) {
        return getValidationErrors(e)
      }

      return e instanceof Error ? e.message : e
    }
  }

  const splittedDOB = student.birthdate?.split('-')

  return (
    <Form
      initialValues={{
        school: student.school ?? undefined,
        graduationYear: student.graduationYear?.toString() ?? undefined,
        firstName: student.firstName ?? undefined,
        lastName: student.lastName ?? undefined,
        phoneNumber: student.phoneNumber ?? undefined,
        wantsEmailMessages: student.wantsEmailMessages ?? undefined,
        birthdate: {
          year: splittedDOB[0],
          month: Number.parseInt(splittedDOB[1]).toString(),
          day: Number.parseInt(splittedDOB[2]).toString(),
        },
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitting,
        valid,
        pristine,
        submitError,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex alignItems="flex-start" flexDirection="column">
            <Flex
              mt="2"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Header1>My Profile</Header1>

              <Action
                aria-label="Close"
                type="button"
                onClick={() => {
                  const confirm = () =>
                    // eslint-disable-next-line no-alert
                    window.confirm(
                      'Do you want to leave this page and lose your unsaved changes?'
                    )

                  if (pristine || confirm()) {
                    close()
                  }
                }}
              >
                <Flex mt="3" pt="2" pb="1" flexDirection="column">
                  <Box
                    borderWidth="2px"
                    borderStyle="solid"
                    borderColor="grey2"
                    borderRadius="50%"
                    height="38px"
                    width="38px"
                    alignSelf="center"
                  >
                    <CloseIcon mt="4px" />
                  </Box>
                  Close
                </Flex>
              </Action>
            </Flex>

            <Grid
              gridColumnGap="5"
              gridTemplateColumns={{default: '1fr', md: '1fr 1fr'}}
              mt="6"
            >
              <Box>
                <ProfileFields student={student} location={location} />
              </Box>

              <Flex mt={{default: '6', md: '0'}} flexDirection="column">
                <Header2>My Preferences</Header2>
                <Box mt="5" borderBottom="1px solid" borderColor="grey2" py="2">
                  <ProfilePreferences />
                </Box>
                <Flex>
                  <Box
                    mt="1"
                    borderBottom="1px solid"
                    borderColor="grey2"
                    py="2"
                  >
                    <Header2>Interview Coach</Header2>
                    <InterviewCoach />
                  </Box>
                </Flex>
              </Flex>
            </Grid>
            <Box mx="auto" mt="2">
              <Button
                disabled={submitting || (!valid && !dirtySinceLastSubmit)}
                busy={submitting}
                type="submit"
              >
                Save
              </Button>

              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </Box>
          </Flex>
        </form>
      )}
    </Form>
  )
}

const ProfilePage = ({location}: PageRendererProps) => (
  <Layout>
    <SEO title="My Profile" />
    <AuthRequired>{() => <Profile location={location} />}</AuthRequired>
  </Layout>
)

export default ProfilePage
