import {FieldValidator} from 'final-form'
import {WindowLocation} from '@reach/router'
import {useField} from 'react-final-form'
import {useState} from 'react'

import {StudentPartsFragment} from 'src/graphql-generated'
import {
  ageGreaterOrEq,
  composeValidators,
  required,
  updatedBd,
  validDate,
  validTelephone,
} from 'src/form/validation'
import {normalizePhone, onlyDigits} from 'src/form/parse'
import {Grid, Box} from 'src/components/Box'
import RoundedTextField, {
  BasicRoundedTextField,
} from 'src/components/form/RoundedTextField'
import NumberField from 'src/components/form/NumberField'

import SchoolField from './form/SchoolField'
import {SmallBody, ExtraSmallBody, Body} from './text'
import {ActionLink} from './Action'
import DateField, {DateFormValue} from './form/DateField'

interface Props {
  student: StudentPartsFragment
  location: WindowLocation
}

const Profile = ({student, location}: Props) => {
  const queryParams = new URLSearchParams(location.search)
  const {meta} = useField('birthdate')
  const [isDirty, setIsDirty] = useState(meta.touched || meta.dirty)
  return (
    <Grid gridGap="3">
      <div>
        <NumberField
          label="Mobile number"
          name="phoneNumber"
          type="tel"
          inputMode="tel"
          pattern=".*"
          format={normalizePhone}
          parse={onlyDigits}
          validate={(value, allValues) =>
            value && validTelephone(value, allValues)
          }
          TextFieldComponent={BasicRoundedTextField}
        />
        <SmallBody color="grey5" mt="2">
          By adding your number, you’ll receive text nudges on our campaigns,
          important reminders, scholarship drops, and more! (Msg &amp; Data
          rates may apply)
        </SmallBody>
      </div>

      <SchoolField name="school" label="School" />

      <NumberField
        label="High School Graduation Year"
        name="graduationYear"
        min={1900}
        max={2099}
        TextFieldComponent={BasicRoundedTextField}
      />

      <RoundedTextField label="First Name" name="firstName" />

      <RoundedTextField label="Last Name" name="lastName" />

      <Body
        css={{
          marginBottom: '-30px',
        }}
      >
        When is your birthday?
      </Body>

      <DateField
        validate={
          composeValidators(
            required,
            validDate,
            ageGreaterOrEq(13),
            updatedBd(
              !isDirty && queryParams.get('updatedBd') === 'true',
              setIsDirty
            )
          ) as FieldValidator<DateFormValue>
        }
        showErrorInitially={queryParams.get('updatedBd') === 'true'}
        name="birthdate"
      />

      <BasicRoundedTextField
        label="Email"
        inputProps={{
          value: student.email,
          type: 'text',
          readOnly: true,
          id: 'email',
        }}
        labelProps={{htmlFor: 'email'}}
      />

      <ActionLink aria-label="Reset Password" to="/reset-password">
        <BasicRoundedTextField
          label="Password"
          inputProps={{
            value: '••••••••••••',
            type: 'text',
            readOnly: true,
            id: 'password',
          }}
          labelProps={{htmlFor: 'password'}}
          linkify
        />
      </ActionLink>

      <Box mx="20px">
        <ExtraSmallBody color="grey5">
          Please email{' '}
          <a href="mailto:hello@getschooled.com">hello@getschooled.com</a> if
          your birthday or email is incorrect.
        </ExtraSmallBody>
      </Box>
    </Grid>
  )
}

export default Profile
